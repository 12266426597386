import React from "react";

import {
  CtaWrapperStyled,
  CtaInnerStyled,
  CtaStyled,
  CtaContentStyled,
  CtaContentHeadingStyled,
  CtaContentSubheadingStyled,
  CtaContentActionsStyled,
  CtaMediaStyled,
  CtaMediaContainerStyled,
  CtaMediaItemStyled,
  CtaButtonStyled,
  CtaMediaMaskStyled,
  CtaButtonStyledNative,
} from "./styles";
import theme from "./../../themes/theme";

import Inner from "./../Inner";
import DottedBg from "./../DottedBg";
import Image from "./../Image";
import { Heading4, Heading8 } from "./../Typography";

interface IProps {
  heading: string;
  subheading: string;
  image?: any;
  isAlt?: boolean;
  leftButtonText: string;
  leftButtonUrl: string;
  leftButtonTarget?: string;
  rightButtonText: string;
  rightButtonUrl: string;
}

const CtaSection: React.FC<IProps> = ({
  heading,
  subheading,
  image,
  isAlt,
  leftButtonText,
  leftButtonUrl,
  leftButtonTarget,
  rightButtonText,
  rightButtonUrl,
}) => (
  <CtaWrapperStyled {...{ isAlt }}>
    {!isAlt && <DottedBg />}
    <CtaInnerStyled>
      <Inner isSmall>
        <CtaStyled>
          <CtaContentStyled>
            <CtaContentHeadingStyled>
              <Heading8
                as="h2"
                fontWeight={`${theme.fontWeight.semiBold}`}
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            </CtaContentHeadingStyled>
            <CtaContentSubheadingStyled>
              <Heading4 fontWeight={`${theme.fontWeight.light}`}>
                {subheading}
              </Heading4>
            </CtaContentSubheadingStyled>
            <CtaContentActionsStyled>
              {leftButtonText && (
                leftButtonUrl.indexOf('https://') !== -1
                  ? <CtaButtonStyledNative
                    $isLeft={true}
                    href={leftButtonUrl}
                    target={leftButtonTarget}
                  >
                    {leftButtonText}
                  </CtaButtonStyledNative>
                  : <CtaButtonStyled
                    to={leftButtonUrl}
                    $isLeft={true}
                  >
                    {leftButtonText}
                  </CtaButtonStyled>
              )}
              {rightButtonText && (
                rightButtonText.indexOf('https://') !== -1 
                  ? <CtaButtonStyledNative
                    $isRight={true}
                    href={rightButtonUrl}
                  >
                    {rightButtonText}
                  </CtaButtonStyledNative>
                  : <CtaButtonStyled
                    to={rightButtonUrl}
                    $isRight={true}
                  >
                    {rightButtonText}
                  </CtaButtonStyled>
              )}
            </CtaContentActionsStyled>
          </CtaContentStyled>
          {image && (
            <CtaMediaStyled>
              <CtaMediaContainerStyled>
                <CtaMediaMaskStyled />
                <CtaMediaItemStyled>
                  <Image fluid={image} alt="Cta section image" />
                </CtaMediaItemStyled>
              </CtaMediaContainerStyled>
            </CtaMediaStyled>
          )}
        </CtaStyled>
      </Inner>
    </CtaInnerStyled>
  </CtaWrapperStyled>
);

export default CtaSection;
