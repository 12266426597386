import React from 'react';
import { format } from 'date-fns';

import {
  BlogItemStyled,
  BlogItemMediaStyled,
  BlogItemContentStyled,
  BlogItemTitleStyled,
  BlogItemSubtitleStyled,
  BlogItemDescStyled,
  BlogItemActionStyled,
  BlogItemActionButtonStyled,
} from './styles';
import Img from './../Image';
import IStrapiBlogPost from '../../interfaces/IStrapiBlogPost';

interface IProps {
  data: IStrapiBlogPost;
}

const BlogItem: React.FC<IProps> = ({ data }) => {
  const { featuredImage, title, seo, slug, published_at, content } = data;

  const { author } = seo;

  return (
    <BlogItemStyled to={`/blogs/${slug}/`}>
      <BlogItemMediaStyled>
        <Img fluid={['', featuredImage.localFile]} alt={`${title} image`} />
      </BlogItemMediaStyled>
      <BlogItemContentStyled>
        <BlogItemTitleStyled>
          <div dangerouslySetInnerHTML={{ __html: title }}></div>
        </BlogItemTitleStyled>
        <BlogItemSubtitleStyled>
          BY <span>{author}</span> / {format(new Date(published_at), 'MMMM dd, yyyy')}
        </BlogItemSubtitleStyled>
        <BlogItemDescStyled
          dangerouslySetInnerHTML={{ __html: content.split(' ').slice(0, 54).join(' ') + ' [...]' }}
        />
        <BlogItemActionStyled>
          <BlogItemActionButtonStyled>Read More</BlogItemActionButtonStyled>
        </BlogItemActionStyled>
      </BlogItemContentStyled>
    </BlogItemStyled>
  );
};

export default BlogItem;
