import styled from 'styled-components';

import { media } from './../../common/MediaQueries';

const BlogListStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -22px;
  padding: 0;
  list-style: none;
`;

const BlogListItemStyled = styled.li`
  display: flex;
  max-width: 100%;
  flex-basis: 100%;
  padding: 14px 22px;

  @media ${media.phone} {
    max-width: 50%;
    flex-basis: 50%;
  }
`;

export { BlogListStyled, BlogListItemStyled };
