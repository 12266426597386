import React from "react";

import { BlogListStyled, BlogListItemStyled } from "./styles";
import BlogListItem from "./../BlogListItem";
import IStrapiBlogPost from "../../interfaces/IStrapiBlogPost";

interface IProps {
  items: IStrapiBlogPost[];
}

const BlogList: React.FC<IProps> = ({ items }) => {
  return (
    <BlogListStyled>
      {items.map((node) => {
        return (
          <BlogListItemStyled key={node.id}>
            <BlogListItem data={node} />
          </BlogListItemStyled>
        );
      })}
    </BlogListStyled>
  );
};

export default BlogList;
