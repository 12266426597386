import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/Seo';
import Layout from '../components/Layout';
import Container from '../components/Container';
import ContentWrapper from '../components/ContentWrapper';
import Section from '../components/Section';
import Inner from '../components/Inner';
import Banner from '../components/Banner';
import BlogList from '../components/BlogList';
import { Heading6, Typo10 } from '../components/Typography';
import { Flex, FlexItem } from '../components/Flex';
import CtaSection from '../components/CtaSection';
import IStrapiBlogsPage from '../interfaces/IStrapiBlogsPage';
import IStrapiBlogPost from '../interfaces/IStrapiBlogPost';

interface Props {
  data: {
    strapiBlogsPage: IStrapiBlogsPage;
    allStrapiBlogPosts: { nodes: IStrapiBlogPost[] };
  };
}

const BlogPage = ({ data }: Props) => {
  const { seo, bannerDesktop, bannerMobile, ctaImage } = data?.strapiBlogsPage || {};
  const posts = data?.allStrapiBlogPosts.nodes || [];

  return (
    <Layout headerLocation="blog" footerMobileMargin={30} footerBorderOnMobile={true}>
      <SEO title={seo.title} description={seo.description} url={'/blogs/'} image={seo.image?.url} />
      <ContentWrapper isBlue>
        <Container>
          <Inner noOffsetMobile>
            <Section>
              <Banner
                image={[bannerMobile.localFile, bannerDesktop.localFile]}
                altText="Blog banner"
                content={
                  <Flex flexDirection={['column', '', 'row']}>
                    <FlexItem
                      width={['100%', '', '60%']}
                      pb={['10px', '', '0']}
                      pt={['16px', '', '60px']}
                    >
                      <Container width={'100%'} pr={['', '62px']}>
                        <Heading6 as="h1" fontWeight="700">
                          Welcome to our blog
                        </Heading6>
                        <Typo10 fontWeight="400">
                          Your source for remote work insights & inspiration
                        </Typo10>
                      </Container>
                    </FlexItem>
                  </Flex>
                }
              />
            </Section>
          </Inner>
        </Container>

        <Container
          zIndex={['1', '1', 'auto']}
          bg={['white', 'white', 'transparent']}
          pt={['25px', '35px']}
          pb={['25px', '50px']}
        >
          <Inner>
            <Section>
              <Inner bg="white" isRelative isSmall noOffsetMobile>
                <BlogList items={posts} />
              </Inner>
            </Section>
          </Inner>
        </Container>
        <Container pt={['220px', '180px', '0']} pb={['0', '86px']}>
          <Inner noOffsetMobile>
            <Section ptBody={['', '2.5rem']}>
              <CtaSection
                isAlt
                heading="<p>We want to help you find an <mark>amazing match</mark></p>"
                subheading="Get in touch and let's get started"
                image={['', ctaImage.localFile]}
                leftButtonUrl={`https://${process.env.GATSBY_PUBLIC_DOMAIN}/companies`}
                leftButtonText="Hire a great candidate"
                leftButtonTarget="_blank"
                rightButtonUrl="/jobs/"
                rightButtonText="Get hired by a great company"
              />
            </Section>
          </Inner>
        </Container>
      </ContentWrapper>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    strapiBlogsPage {
      seo {
        title
        description
        image {
          url
        }
      }
      bannerDesktop {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              width: 1400
              quality: 80
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      bannerMobile {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              width: 768
              quality: 60
              formats: [AUTO, WEBP]
            )
          }
        }
      }
      ctaImage {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: NONE
              width: 320
              quality: 70
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
    allStrapiBlogPosts(limit: 10, sort: { fields: published_at, order: DESC }) {
      nodes {
        slug
        id
        title
        content
        featuredImage {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
        }
        published_at
        seo {
          author
        }
      }
    }
  }
`;
